.tooltip {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #62a4cd;
  cursor: pointer;
  margin-left: 20px;

  img {
    width: 18px;
    height: 18px;
  }
}

.white_tooltip {
  width: 24px;
  height: 24px;
  margin: 0 15px 0 10px;
  cursor: pointer;
}
