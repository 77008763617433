.tooltiptext {
  visibility: hidden;
  font-size: 16px;
  font-family: SFProDisplay-SemiBold;
  position: absolute;
  z-index: 1;
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  color: #393939;
  padding: 5px 5px;
  border-radius: 2px;
}

.profile_tooltip:hover .tooltiptext {
  visibility: visible;
}
