.upload_team_image_wrapper,
.upload_image_wrapper {
  width: 200px;
  height: 100px;
  border-radius: 4px;
  border: solid 1px #c7c7c7;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    width: 0px;
    height: 0px;
  }

  img {
    width: 42px;
    height: 42px;
  }

  .upload_image-large_text {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #393939;
    font-family: 'SFProDisplay-Medium';
  }

  .upload_image-small_text {
    margin-top: 4px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.12px;
    text-align: left;
    color: #393939;
    font-family: 'SFProDisplay-Medium';
    span {
      color: #62a4cd;
      cursor: pointer;
    }
  }
}

.upload_team_image_wrapper {
  width: 130px;
  height: 68px;
  padding: 5px 0;
  justify-content: center;
  flex-direction: column;
  display: flex;

  img {
    width: 22px;
    height: 22px;
  }

  .upload_image-large_text {
    margin-top: 5px;
    margin-top: 0;
    font-size: 11px;
  }

  .upload_image-small_text {
    margin-top: 1px;
    font-size: 8px;
  }
}

.show_team_image_wrapper,
.show_image_wrapper {
  width: 262px;
  max-height: 162px;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 4px;

  img {
    width: inherit;
    height: 162px;
    border-radius: 4px;
    object-fit: contain;
  }

  .delete_image_button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #62a4cd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.show_team_image_wrapper {
  width: 130px;
  height: 80px;

  img {
    width: inherit;
    height: inherit;
    border-radius: 4px;
    object-fit: cover;
  }

  .delete_image_button {
    width: 12px;
    height: 12px;
    top: 6px;
    right: 6px;

    img {
      width: 8px;
      height: 8px;
    }
  }
}
