.error_text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: inherit;

  img {
    width: 16px;
    height: 16px;
  }
  p {
    margin: 0 0 0 4px;
    font-family: 'SFProDisplay-Regular';
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: -0.12px;
  }
}

.input_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.multiple_formHelper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .value_counter {
    font-size: 12px;
    color: #393939;
  }
}
