.session_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-position: center;
  background-image: url(../../images/session_background.png);
  background-repeat: no-repeat;
  background-size: cover;

  .logo {
    position: absolute;
    top: 29px;
    left: 30px;
    width: 210px;
  }
}

.verification-title {
  text-align: center;
  margin-bottom: 10px !important;
}

.verification-container {
  margin: 35px 0;
  .verification-input {
    width: 54px;
    height: 72px;
    border-radius: 12px;
    border: #919193 3px;
    font-weight: 700;
    font-size: 33px;
    line-height: 39.94px;

    .MuiOutlinedInput-notchedOutline {
      border-width: 3px;
      border-radius: 12px;
    }

    .MuiOutlinedInput-input {
      text-align: center;
      font-size: 33px;
      font-weight: 700;
      font-family: 'Inter';
    }
  }
}

.verification-create-btn {
  align-self: center;
  height: 58px;
  width: 352px;
}

.verification-resend-btn {
  align-self: center;
  margin-top: 30px !important;
  width: 100px !important;
  color: #1e1d22 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.loader-container {
  display: block;
  padding-top: 30px;
  margin: auto;
}
