.chat_wrapper {
  width: calc(100% + 72px);
  margin: 0 -36px;
  height: calc(100vh - 80px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f7f7f7;

  .chat_list {
    width: 350px;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    padding: 16px;

    border-right: 1px solid #efefef;
    position: relative;
    overflow-y: auto;

    .messages_title {
      font-size: 28px;
      font-family: 'SFProDisplay-SemiBold';
      line-height: 1.43;
      letter-spacing: 0.28px;
      color: #393939;
      position: absolute;
      top: 16px;
      left: 16px;
      background-color: inherit;
    }

    .scroll_wrapper {
      width: 100%;
      margin-top: 80px;
    }

    .active {
      background-color: #eff9ff !important;
    }

    .chat_item {
      padding: 16px 16px 12px 16px;
      border-radius: 8px;
      background-color: #fff;
      width: calc(100% - 32px);
      height: calc(92px - 28px);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 8px;
      cursor: pointer;
      position: relative;

      .chat_info {
        max-width: calc(100% - 64px);
      }

      .last_message_time {
        font-size: 10px;
        font-family: 'SFProDisplay-Medium';
        color: #858585;
        text-align: right;
        position: absolute;
        right: 8px;
        top: 8px;
      }

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f7f7f7;
      }

      .chat_contact_name {
        font-size: 16px;
        color: #393939;
        font-family: 'SFProDisplay-Medium';
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      .chat_last_message {
        font-size: 14px;
        font-family: 'SFProDisplay-Medium';
        color: #858585;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        margin-bottom: 4px;
      }

      .chat_project_name {
        font-size: 10px;
        color: #fff;
        font-family: 'SFProDisplay-Medium';
        padding: 4px 8px;
        background-color: #62a4cd;
        border-radius: 4px;
        max-width: 100%;
      }
    }
  }
}

.chat {
  width: calc(100vw - 383px);
  max-height: calc(100vh - 80px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;

  .header_avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-right: 12px;
  }

  .avatar_and_name {
    display: flex;
    align-items: center;
    line-height: 1.5;
    color: #393939;
    font-family: 'SFProDisplay-SemiBold';
  }

  .more_image {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .message_input_wrapper {
    width: calc(100% - 48px);
    background-color: #fff;
    padding: 16px 24px 16px 24px;

    img {
      cursor: pointer;
    }

    .paperclip_icon {
      width: 24px;
      height: 24px;
      align-self: flex-end;
    }

    .end_buttons {
      display: flex;
      align-items: center;
      align-self: flex-end;
    }
    .smile_icon {
      width: 32px;
      height: 32px;
    }

    .send_icon {
      width: 44px;
      height: 44px;
    }
  }
}

.messages_container {
  flex-grow: 1;
  height: calc(100vh - 250px);
  width: calc(100% - 48px);
  overflow-x: auto;
  direction: rtl;
  transform: rotate(180deg);
  padding: 24px 24px 24px 24px;
  background-color: #fff;

  .message_container {
    flex-direction: row-reverse;
    display: flex;
    align-items: flex-end;
    transform: rotate(180deg);
    margin-top: 16px;
  }

  .owner_message {
    flex-direction: row !important;
  }

  .message_avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
  }

  .message_time {
    font-size: 12px;
    font-family: 'SFProDisplay-Medium';
    color: #393939;
    line-height: 1.17;
  }

  .message_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    direction: ltr;
  }

  .owner_content {
    align-items: flex-start;
    text-align: left;
    direction: ltr;
  }

  .text_content {
    font-size: 14px;
    font-family: 'SFProDisplay-Medium';
    padding: 16px 24px 16px 24px;
    color: #393939;
    line-height: 1.17;
    background-color: #f7f7f7;
    border-radius: 15px 15px 15px 0px;
    margin-bottom: 8px;
    white-space: pre-wrap;
    max-width: 80%;
    position: relative;
  }

  .owner_text {
    border-radius: 15px 15px 0px 15px;
    background-color: #eff9ff;
  }
}
