.logo {
  width: 210px;
  height: 23px;
}

.menu_icon {
  width: 24px;
  height: 24px;
  margin-right: 40px;
  cursor: pointer;
}

.tabs_wrapper {
  width: 200px;
}

.none {
  display: none !important;
}

.scrolable {
  max-height: 100vh;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.support-link {
  display: flex;
  align-items: center;
  color: #393939;
  height: 48px;
  font-size: 14px;
  font-family: SFProDisplay-Medium;
  line-height: 1.29;
  text-decoration: none;
}
