.drawer_container {
  width: max-content;
  position: absolute;
  top: 80px;
  left: 0px;
}

.dot {
  min-width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 12px;
}

.status_label_wrapper {
  z-index: 2000;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 15px 15px;
  padding: 12px 32px 12px 32px;
  position: absolute;
  left: calc(100% + 0px);
  top: 2px;
  font-size: 16px;
  color: #fff;
  font-family: 'SFProDisplay-Regular';
  white-space: nowrap;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
