.project_form_container {
  padding: 58px 36px 100px 32px;
  width: calc(100% - 32px);
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 226px);
  margin-right: -36px;
  position: relative;
}

.next_back_buttons_container {
  height: 80px;
  width: calc(100% - 314px);
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  z-index: 100;
}

.underline {
  width: calc(100% + 16px);
  border-bottom: solid 1px #858585;
  margin-left: -8px;
}

.underline_gray {
  width: calc(100% + 16px);
  border-bottom: solid 1px #ececec;
  margin-left: -8px;
  // margin-top: 20px;
  // margin-bottom: 20px;
}

.underline_with_numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;

  .underline_line {
    width: 50%;
    border-top: 1px solid #858585;
  }

  .underline_index {
    margin: 0 5px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #62a4cd;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SFProDisplay-SemiBold';
  }
}

.about_buttons_block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 50px auto;
}

.about_buttons_block_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}

.show_video_wrapper {
  width: 710px;
  height: auto;
  position: relative;
  margin-top: 30px;
  z-index: 3000;
  .delete_video_button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #62a4cd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.automatically_publish_wraper {
  width: calc(100% - 60px);
  border-radius: 4px;
  background-color: #f4fafe;
  padding: 32px;
}

.buisness-input {
  font-size: 16px;
  padding-left: 15px;
}

.buisness-input:focus {
  outline: none;
}

.history_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .horizontal_line {
    position: absolute;
    height: inherit;
    border: 1px solid #f4f4f4;
    left: 0;
  }

  .history_item {
    width: 435px;
    height: auto;
    border-radius: 16px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.06);
    margin-top: 48px;
    padding: 24px;
    position: relative;

    .color_cyrcle {
      position: absolute;
      height: 24px;
      width: 24px;
      border-radius: 100%;
      left: -71px;
      top: calc(50% - 11px);
    }

    .color_icon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
    }

    .history-status {
      font-size: 18px;
      line-height: 1.56;
      color: #393939;
      font-family: 'SFProDisplay-SemiBold';
    }

    .history-message {
      font-size: 14px;
      line-height: 1.43;
      color: #606060;
      font-family: 'SFProDisplay-Medium';
    }

    .date_time_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 1.17;
      color: #393939;
      margin-top: 25px;
      div {
        display: flex;
        align-items: center;
      }

      img {
        width: 15px;
        height: 15px;
      }

      .time_icon {
        margin-right: 5px;
      }

      .calendar_icon {
        margin-left: 5px;
      }
    }
  }
}

.investors_container {
  max-width: 900px;
  margin: auto;
  margin-top: 48px;

  .investors_item {
    width: calc(100% - 59px);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 43px 0 16px;
    border-radius: 8px;
    background-color: #f4fafe;
    margin-bottom: 8px;

    .no_avatar {
      width: 38px;
      height: 38px;
      border-radius: 4px;
      margin-right: 32px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .investor_info {
      display: flex;
      align-items: center;

      img {
        width: 38px;
        height: 38px;
        border-radius: 4px;
        margin-right: 32px;
      }

      .name_text {
        font-size: 16px;
        line-height: 1.5;
        color: #393939;
        font-family: 'SFProDisplay-Regular';
      }
    }

    .investor_action {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background-color: #daeaf5;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.saved_alert {
  position: absolute;
  bottom: 40px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(65, 220, 108);
  z-index: 1000;
  border-radius: 0 0 10px 10px;
  color: #fff;
  font-family: 'SFProDisplay-SemiBold';
  opacity: 0;
  transition:
    bottom 0.4s,
    opacity 0.5s;
}

.visible {
  bottom: 0px;
  opacity: 1;
}
