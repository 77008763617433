.upload_doc_wrapper {
  width: 200px;
  height: 100px;
  border-radius: 4px;
  border: solid 1px #c7c7c7;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    width: 0px;
    height: 0px;
  }

  img {
    width: 42px;
    height: 42px;
  }

  .upload_doc-large_text {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #393939;
    font-family: 'SFProDisplay-Medium';
  }

  .upload_doc-small_text {
    margin-top: 4px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.12px;
    text-align: left;
    color: #393939;
    font-family: 'SFProDisplay-Medium';
    span {
      color: #62a4cd;
      cursor: pointer;
    }
  }
}

.show_doc_wrapper {
  width: 160px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #393939;
  border-radius: 4px;
  position: relative;
  flex-direction: column;

  .docs_name {
    margin-top: 12px;
    font-family: 'SFProDisplay-Medium';
    font-size: 12px;
    line-height: 1.17;
    text-align: center;
    color: #62a4cd;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .delete_image_button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #62a4cd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }
}
