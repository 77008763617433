.container {
  margin-top: 20px;
  width: 400px;

  @media (max-width: 600px) {
    width: 90%;
  }

  &-main-img {
    width: 360px;
    height: auto;
    max-height: 250px;
    object-fit: cover;

    @media (max-width: 600px) {
      width: -webkit-fill-available;
    }
  }

  &-owner {
    color: #a0a0a0;
    font-size: 17px;
    max-width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 10px;
  }

  &-likes-img {
    width: 20px;
    height: 20px;
  }

  &-likes {
    margin: auto 5px;
    color: #9c9c9c;
  }

  &-likes-title {
    color: #7c8d9b;
  }

  &-title {
    width: 100%;
    margin: 10px 0;
    font-size: 26px;
    font-weight: 900;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &-shortDescription {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: auto;

  &-icons {
    width: 180px;
    height: auto;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: 0 20px;

    &-icons {
      width: 160px;
      height: auto;
    }
  }
}
