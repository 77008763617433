.inactive_radio {
  width: 24px;
  height: 24px;
  border: solid 1px #858585;
  border-radius: 100%;
}

.active_radio {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background-color: #62a4cd;
  img {
    width: 24px;
    height: 24px;
  }
}
