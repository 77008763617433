.checkbox_icon {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: solid 1px #858585;
}

.checkbox_icon_active {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #62a4cd;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
}
