body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

@font-face {
  font-family: 'SFProDisplay-Black';
  src:
    local('SFProDisplay-Black'),
    url('./fonts/SFProDisplay-Black.ttf') format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-BlackItalic';
  src:
    local('SFProDisplay-BlackItalic'),
    url('./fonts/SFProDisplay-BlackItalic.ttf') format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src:
    local('SFProDisplay-Bold'),
    url(./fonts/SFProDisplay-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-BoldItalic';
  src:
    local('SFProDisplay-BoldItalic'),
    url(./fonts/SFProDisplay-BoldItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-HeavyItalic';
  src:
    local('SFProDisplay-HeavyItalic'),
    url(./fonts/SFProDisplay-HeavyItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-Heavy';
  src:
    local('SFProDisplay-Heavy'),
    url(./fonts/SFProDisplay-Heavy.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-LightItalic';
  src:
    local('SFProDisplay-LightItalic'),
    url(./fonts/SFProDisplay-LightItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-Medium';
  src:
    local('SFProDisplay-Medium'),
    url(./fonts/SFProDisplay-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-MediumItalic';
  src:
    local('SFProDisplay-MediumItalic'),
    url(./fonts/SFProDisplay-MediumItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src:
    local('SFProDisplay-Regular'),
    url(./fonts/SFProDisplay-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-RegularItalic';
  src:
    local('SFProDisplay-RegularItalic'),
    url(./fonts/SFProDisplay-RegularItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-RegularItalic';
  src:
    local('SFProDisplay-RegularItalic'),
    url(./fonts/SFProDisplay-RegularItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-SemiBold';
  src:
    local('SFProDisplay-SemiBold'),
    url(./fonts/SFProDisplay-Semibold.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-SemiBoldItalic';
  src:
    local('SFProDisplay-SemiBoldItalic'),
    url(./fonts/SFProDisplay-SemiboldItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-Thin';
  src:
    local('SFProDisplay-Thin'),
    url(./fonts/SFProDisplay-Thin.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-ThinItalic';
  src:
    local('SFProDisplay-ThinItalic'),
    url(./fonts/SFProDisplay-ThinItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-Ultarlight';
  src:
    local('SFProDisplay-Ultarlight'),
    url(./fonts/SFProDisplay-Ultralight.ttf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-UltarlightItalic';
  src:
    local('SFProDisplay-UltarlightItalic'),
    url(./fonts/SFProDisplay-UltralightItalic.ttf) format('opentype');
}
