.empty_projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  margin-top: 200px;
}

.dashboard_scroll_container {
  margin-left: -36px;
  margin-right: -36px;
  padding-left: 80px;
  padding-right: 80px;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}

.dashboard_container {
  max-width: 1194px;
  margin: auto;
}

@media screen and (max-width: 1355px) {
  .dashboard_container {
    max-width: 796px;
  }
}

@media screen and (max-width: 956px) {
  .dashboard_container {
    max-width: 398px;
  }
}

.firs_line {
  width: 100%;
  border-bottom: 1px solid #ececec;
}

.projects_item_wrapper {
  margin-top: -1px;
  height: 80px;
  border-top: 1px solid #ececec;
  padding: 24px 16px;
  display: flex;
  position: relative;
  cursor: pointer;

  .projects_logo {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 12px;
  }

  .project_no_logo {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-family: 'SFProDisplay-Bold';
    background-color: #e0e0e0;
  }

  .project_status {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 24px;
    right: 8px;
  }

  .project_edit_button {
    width: 40px;
    min-width: 40px;
    height: 13px;
    border-radius: 3px;
    position: absolute;
    top: 23px;
    right: 25px;
    font-size: 7px !important;
    text-align: center;
    line-height: 0px;
    font-weight: 700 !important;
    padding: 0 !important;
    box-shadow: none;
  }

  .projects_item-info {
    padding: 19px 0;
    width: 270px;
    margin-left: 16px;

    .project_title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 18px;
      font-family: 'SFProDisplay-SemiBold';
      text-align: left;
      color: #393939;
    }

    .project_description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-family: 'SFProDisplay-Medium';
      text-align: left;
      color: #858585;
    }
  }
}
